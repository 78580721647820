import React from 'react';
import logo from './logo.svg';
import './App.css';
import inndicoLogo from '../src/images/inndico-logo.svg'

function App() {
  return (
    <div className="App-header">
      <div className='main-container'>
         <img className='main-logo' src={inndicoLogo}alt=""  />
         <p className='main-text'>PÁGINA EN RENOVACIÓN</p>
         <div className='contact-container'>
         <span>CONTACTO</span>
        <a href = "mailto:jteranconsulting@gmail.com">jteranconsulting@gmail.com</a>
        </div>
      </div>
          
         
    </div>
  );
}

export default App;
